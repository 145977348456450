export const FETCH_CATEGORY_DATA = "FETCH_CATEGORY_DATA";
export const FETCH_CATEGORY_DATA_SUCCESS = "FETCH_CATEGORY_DATA_SUCCESS";
export const FETCH_CATEGORY_DATA_FAILURE = "FETCH_CATEGORY_DATA_FAILURE";

export const FETCH_ADDON_SERVICES_DATA="FETCH_ADDON_SERVICES_DATA";
export const FETCH_ADDON_SERVICES_DATA_SUCCESS="FETCH_ADDON_SERVICES_DATA_SUCCESS";
export const FETCH_ADDON_SERVICES_DATA_FAILURE="FETCH_ADDON_SERVICES_DATA_FAILURE";
export const FETCH_SALON_CATEGORY_DATA_SUCCESS = "FETCH_SALON_CATEGORY_DATA_SUCCESS";
export const FETCH_SALON_CATEGORY_DATA_REQUEST = "FETCH_SALON_CATEGORY_DATA_REQUEST";
export const FETCH_SALON_CATEGORY_DATA_ERROR = "FETCH_SALON_CATEGORY_DATA_ERROR";
export const SET_BACK_TO_SERVICES = "SET_BACK_TO_SERVICES";
