import { SET_BACK_TO_SERVICES } from '../actions/category/types';

const initialState = {
    serviceData: null
}

export default function backToServicesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_BACK_TO_SERVICES:
            return { ...state, serviceData: action.data }
        default:
            return state;
    }
}