import { SET_CART_BANNER_FLAG_DATA } from '../actions/Dashboard/types';

const bannerInitialState = {
    cartBannerFlag: false
}

export default function cartBannerFlagReducer(state = bannerInitialState, action) {
    switch (action.type) {
        case SET_CART_BANNER_FLAG_DATA:
            return { ...state, cartBannerFlag: action.data }
        default:
            return state;
    }
}