/**
 *
 * @param {string} key lang[key] to access
 * @param {*} context Override lang and provide variables to template strings
 */
import { useMemo } from "react";
import moment from "moment-timezone";
import { useParams, useLocation } from "react-router-dom";
import { get, replace, uniqBy, isArray, clone,flatMap, find, flatten} from "lodash";
import { textFiltering } from "../../const/default";
import constJson from "../../const/const.json";
import browwax from "../../assets/image/images/brow-wax.svg";
import expressfacials from "../../assets/image/images/express-facials.svg";
import hairstyling from "../../assets/image/images/hair-styling.svg";
import piercing from "../../assets/image/images/Group2.svg";
import hairtreatments from "../../assets/image/images/hair-treatments.svg";
import hair from "../../assets/image/images/hair.svg";
import Haircut from "../../assets/image/Haircut.svg";
import Hairstyle from "../../assets/image/HairStyle.svg";
import Haircolor from "../../assets/image/HairColor.svg";
import RoutesConfig from "../../router/Config";
import { eachStylistHasService } from "./stylistFlowHelper"


export const getText = (key, ...context) => {
  if (key) {
    return typeof get(textFiltering, key) === "function"
      ? get(textFiltering, key)(...context)
      : get(textFiltering, key);
  }

  throw Error(`Translation missing key ${key}`);
};
export const slotFormat = (allSlot, getSelectedData) => {
  let formatedData = []
  allSlot.forEach(slotName => {
    if (getSelectedData.includes(slotName)) { formatedData = [...formatedData, slotName] }
  });
  return formatedData;
};
export const getTotalsBySelectedServicesKey = (selectedServicesObj, key, bookingstate, selectedStylist) => {
  let amount = 0;
if(!!bookingstate){
  flatten(Object.values(selectedServicesObj))[0].bookedState?.services.forEach((data)=> {
    amount = amount + parseInt(data?.finalDurationSec/60);
  });
} else if (selectedStylist && selectedStylist[0] && selectedStylist[0].duration) {
  selectedStylist.map((eachSelectedSylist) => {
    amount = amount + parseInt(eachSelectedSylist.duration);
  });
} else{
  Object.keys(selectedServicesObj).map((each) =>
    selectedServicesObj[each].forEach((eachSelectedService) => {
      amount = amount + parseInt(eachSelectedService[key]);
    })
  );
  } 
  return amount;
};

export const getTotalsBySelectedStylistKey = (selectedStylistObj, key,bookingstate) => {
  let amount = 0;
  
if(!!bookingstate){
  (flatten(Object.values(selectedStylistObj)))[0].bookedState?.services.forEach((data)=> {
    amount = amount + parseInt(data?.finalDurationSec/60);
  });
} else{
  selectedStylistObj.map((eachSelectedSylist) => {
      amount = amount + parseInt(eachSelectedSylist[key]);
    }
    );
}
  return amount;
};

export const priceConveter = (value, precision = 2) =>
  value
    ? `${constJson.currency}${(value / 100).toFixed(precision)}`
    : `${constJson.currency}0.00`;

export const getAppointmentService = (selectedService, key, seviceObject) => {
  let getselectedService;
  if (selectedService) {
    const findCategory = Object.keys(selectedService).find(
      (each) => each === `${key}`
    );

    if (findCategory) {
      selectedService[findCategory].push(seviceObject);
      getselectedService = {
        ...selectedService,
      };
    } else {
      getselectedService = {
        ...selectedService,
        [`${key}`]: [seviceObject],
      };
    }
  } else {
    getselectedService = {
      [`${key}`]: [seviceObject],
    };
  }
  return getselectedService;
};

const getSpecified = (getSingleObject) => {
  const categoryId = [];
  getSingleObject.forEach((each) => categoryId.push(each.serviceID || each?.serviceId));
  return categoryId;
};

export const getFlatObject = (objects) => {
  return flatMap(objects, getSpecified);
};

export const mnToHrConveter = (totalMinutes, precision = 2) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (minutes > 0 && hours > 0) {
    return {
      min: minutes,
      hr: hours,
    };
  } else if (minutes <= 0 && hours > 0) {
    return {
      hr: hours,
    };
  } else if (minutes > 0 && hours <= 0) {
    return {
      min: minutes,
    };
  }
};

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  return useMemo(() => {
    return {
      pathName: location.pathname,
      query: {
        ...params,
      },
    };
  }, [params, location]);
}
const getOneButtonCTA = (buttonProps) => {

}
const getFlowButtons= (enabled) =>{
  let buttonObject = null;
  buttonObject = {
    buttonEnable: enabled,
    url: "/stylist/listing",
    buttonText: getText("appointmentCard.selectStylist"),
    textFiltering: getText("appointmentCard.note"),
    secondary:{
       buttonEnable: enabled, 
       url: "/services",
       buttonText: getText("appointmentCard.selectService"),
    },
    buttonTextStylist: "btStylist:" + getText("appointmentCard.selectStylist"),
  };
  return buttonObject;
}

export const useAppointmentCardButton = (
  selectedServices,
  SelectStores,
  selectedDate,
  isStylistFlow,
  selectedtStylist,
  params
) => {
  const getRouter = params? params: useRouter();
  const {
    pathName,
    query: { serviceName, apptid },
  } = getRouter;
  let buttonObject = null;
  const getURL = replace(pathName, /\//g, "-");
  
  switch (getURL) {
    case "-store-selection":
      if (SelectStores) {
        buttonObject = getFlowButtons(true);
      } else { // May not be needed
        buttonObject = getFlowButtons(false);
      }
      break;

    case "-store-search":
      if (SelectStores) {
        buttonObject = getFlowButtons(true);
      } else {
        buttonObject = getFlowButtons(false);
      }
      break;
    case "-services":
      if (SelectStores && selectedServices) {
        buttonObject = {
          url: "/calendar/browse",
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectdateTime"),
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      }

      break;
    case `-services-${serviceName}`:
      if (SelectStores && selectedServices) {
        buttonObject = {
          url: "/calendar/browse",
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectdateTime"),
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      }
      break;
    case "-stylist-selection":
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        // TODO this should be enabled if a time is selected
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering: getText("appointmentCard.note"),
        };
      }

      break;
    case `-rebook-bookagain-booking-rebook-${apptid}`:
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering: "Note: These services are not booked yet.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
          editAppointmnetCard: true,
          editStore: true,
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering: "Note: These services are not booked yet.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
          editAppointmnetCard: true,
          editStore: true,
        };
      }
      break;
    case "-calendar-browse":
      if (SelectStores && selectedServices && selectedDate?.dates) {
        buttonObject = {
          url: "/stylist/selection",
          buttonText: getText("appointmentCard.chooseStylist"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.chooseStylist"),
          textFiltering: getText("appointmentCard.note"),
        };
      }
      break;
    case "-booking-date-reselect":
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering:
            "Note: By choosing review you will be canceling your old appointment and reserving a new one.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering:
            "Note: By choosing review you will be canceling your old appointment and reserving a new one.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
        };
      }
      break;
    case "-picknewdate":
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          textLink: !isStylistFlow ? getText("appointmentCard.textLink") : null,
          editAppointmnetCard: true,
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering: getText("appointmentCard.note"),
          textLink: !isStylistFlow ? getText("appointmentCard.textLink") : null,
          editAppointmnetCard: true,
        };
      }
      break;
    case `-booking-reschedule-${apptid}`:
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering:
            "Note: By choosing review you will be canceling your old appointment and reserving a new one.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
          editAppointmnetCard: true,
          editStore: true,
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering:
            "Note: By choosing review you will be canceling your old appointment and reserving a new one.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
          editAppointmnetCard: true,
          editStore: true,
        };
      }
      break;
    case "-stylist-selectionbycategory":
      if (SelectStores && selectedServices) {
        buttonObject = {
          url: "/calendar/browse",
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectdateTime"),
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      }

      break;
    case "-stylist-listing":
      if(SelectStores && eachStylistHasService(selectedtStylist,selectedServices)) {
        buttonObject = {
          url: getText("pickANewDateRoutePath"),
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices")
        }
      }
      else {
        buttonObject = {
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: false,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        }
      }
      
      break;
    case "-stylist-services":
      if(SelectStores && eachStylistHasService(selectedtStylist,selectedServices)) {
        buttonObject = {
          url: getText("pickANewDateRoutePath"),
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices")
        };
      }
      else {
        buttonObject = {
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: false,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      }
      break;
    case `-stylist-services-${serviceName}`:
      if(SelectStores && eachStylistHasService(selectedtStylist,selectedServices)) {
        buttonObject = {
          url: getText("pickANewDateRoutePath"),
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices")
        }
      }
      else {
        buttonObject = {
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: false,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        }
      }
      break;
    default:
      buttonObject = {
        buttonEnable: false,
        buttonText: getText("appointmentCard.selectService"),
        textFiltering: getText("appointmentCard.note"),
      };
      break;
  }
  // return {
  //     "primary":buttonObject,
  //     "secondary":secondaryButtonObject
  // }
  return buttonObject;
};

export const fetchLastUrlSection = (url) => {
  const urlPart = url && url.split('/');
  return ( urlPart && urlPart.length > 0 ) ? urlPart[urlPart.length-1] : null;
}

export const useAppointmentCardButtonV2 = (
  selectedServices,
  SelectStores,
  selectedDate,
  isStylistFlow,
  selectedtStylist,
  prevUrl
) => {
  let buttonObject = null;
  const getURL = replace(prevUrl, /\//g, "-");  
  const lastParamsIfAny = fetchLastUrlSection(prevUrl);
  switch (getURL) {
    case "-store-selection":
      if (SelectStores) {
        buttonObject = getFlowButtons(true);
      } else { // May not be needed
        buttonObject = getFlowButtons(false);
      }
      break;

    case "-store-search":
      if (SelectStores) {
        buttonObject = getFlowButtons(true);
      } else {
        buttonObject = getFlowButtons(false);
      }
      break;
    case "-services":
      if (SelectStores && selectedServices) {
        buttonObject = {
          url: "/calendar/browse",
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectdateTime"),
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      }

      break;
    case `-services-${lastParamsIfAny}`:
      if (SelectStores && selectedServices) {
        buttonObject = {
          url: "/calendar/browse",
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectdateTime"),
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      }
      break;
    case "-stylist-selection":
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        // TODO this should be enabled if a time is selected
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering: getText("appointmentCard.note"),
        };
      }

      break;
    case `-rebook-bookagain-booking-rebook-${lastParamsIfAny}`:
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering: "Note: These services are not booked yet.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
          editAppointmnetCard: true,
          editStore: true,
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering: "Note: These services are not booked yet.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
          editAppointmnetCard: true,
          editStore: true,
        };
      }
      break;
    case "-calendar-browse":
      if (SelectStores && selectedServices && selectedDate?.dates) {
        buttonObject = {
          url: "/stylist/selection",
          buttonText: getText("appointmentCard.chooseStylist"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.chooseStylist"),
          textFiltering: getText("appointmentCard.note"),
        };
      }
      break;
    case "-booking-date-reselect":
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering:
            "Note: By choosing review you will be canceling your old appointment and reserving a new one.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering:
            "Note: By choosing review you will be canceling your old appointment and reserving a new one.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
        };
      }
      break;
    case "-picknewdate":
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          textLink: !isStylistFlow ? getText("appointmentCard.textLink") : null,
          editAppointmnetCard: true,
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering: getText("appointmentCard.note"),
          textLink: !isStylistFlow ? getText("appointmentCard.textLink") : null,
          editAppointmnetCard: true,
        };
      }
      break;
    case `-booking-reschedule-${lastParamsIfAny}`:
      if (
        SelectStores &&
        selectedServices &&
        selectedDate &&
        selectedDate?.dates &&
        selectedDate?.time
      ) {
        buttonObject = {
          url: "/appointment/review",
          buttonText: getText("appointmentCard.selectReview"),
          buttonEnable: true,
          textFiltering:
            "Note: By choosing review you will be canceling your old appointment and reserving a new one.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
          editAppointmnetCard: true,
          editStore: true,
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectReview"),
          textFiltering:
            "Note: By choosing review you will be canceling your old appointment and reserving a new one.",
          textLink: getText("appointmentCard.textLink"),
          textLinkUrl: "/dashboard",
          editAppointmnetCard: true,
          editStore: true,
        };
      }
      break;
    case "-stylist-selectionbycategory":
      if (SelectStores && selectedServices) {
        buttonObject = {
          url: "/calendar/browse",
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      } else {
        buttonObject = {
          buttonEnable: false,
          buttonText: getText("appointmentCard.selectdateTime"),
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      }

      break;
    case "-stylist-listing":
      if(SelectStores && eachStylistHasService(selectedtStylist,selectedServices)) {
        buttonObject = {
          url: getText("pickANewDateRoutePath"),
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices")
        }
      }
      else {
        buttonObject = {
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: false,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        }
      }
      
      break;
    case "-stylist-services":
      if(SelectStores && eachStylistHasService(selectedtStylist,selectedServices)) {
        buttonObject = {
          url: getText("pickANewDateRoutePath"),
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices")
        };
      }
      else {
        buttonObject = {
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: false,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        };
      }
      break;
    case `-stylist-services-${lastParamsIfAny}`:
      if(SelectStores && eachStylistHasService(selectedtStylist,selectedServices)) {
        buttonObject = {
          url: getText("pickANewDateRoutePath"),
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: true,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices")
        }
      }
      else {
        buttonObject = {
          buttonText: getText("appointmentCard.selectdateTime"),
          buttonEnable: false,
          textFiltering: getText("appointmentCard.note"),
          serviceText: getText("appointmentCard.yourServices"),
          noServicesSelected: getText("appointmentCard.noServicesSelected"),
        }
      }
      break;
    default:
      buttonObject = {
        buttonEnable: false,
        buttonText: getText("appointmentCard.selectService"),
        textFiltering: getText("appointmentCard.note"),
      };
      break;
  }

  return buttonObject;
};

export const getServiceCategoryImage = (slug) => {
  let img = hair;
  const sligName = replace(slug, /-/g, "");
  switch (sligName) {
    case "browwax":
      img = browwax;
      break;
    case "expressfacials":
      img = expressfacials;
      break;
    case "hairstyling":
      img = hairstyling;
      break;
    case "hairtreatments":
      img = hairtreatments;
      break;
    case "piercing":
      img = piercing;
      break;
    case "Haircut":
      img = Haircut;
      break;
    case "Hairstyle":
      img = Hairstyle;
      break;
    case "Haircolor":
      img = Haircolor;
      break;
    default:
      img = hair;
  }
  return img;
};
export const getCategorySort = (categoryCardDetails) => {
  let sortedObjs = categoryCardDetails.sort((a, b) => {
    return a.order - b.order;
  });
  return sortedObjs;
};

export const getStepper = (step) => {
  const totalSteps = uniqBy(
    RoutesConfig.filter((each) => each.stepper),
    "stepper"
  );
  let stepper;
  switch (step) {
    case 1:
      stepper = getText("stepper.step1", step, totalSteps.length);
      break;
    case 2:
      stepper = getText("stepper.step2", step, totalSteps.length);
      break;
    case 3:
      stepper = getText("stepper.step3", step, totalSteps.length);
      break;
    case 4:
      stepper = getText("stepper.step4", step, totalSteps.length);
      break;
    case 5:
      stepper = getText("stepper.step5", step, totalSteps.length);
      break;
    default:
      stepper = "";
  }
  return stepper;
};

export const setTodate = (selectedDate, format) => {
  return moment(moment(selectedDate)).toDate();
};

export const setDateForAppointmentCard = (
  selectedDate,
  selectedDateFormat,
  apptFormat
) => {
  return moment(selectedDate, selectedDateFormat).clone().format(apptFormat);
};

export const getStylistPrice = (priceArray) => {
  let sum = 0;
  priceArray?.forEach((each) => (sum += parseInt(each.price)));
  return sum;
};

export const getServicePrice = (priceArray) => {
  let sum = 0;
  priceArray?.forEach((each) => (sum += parseInt(each.Price)));
  return sum;
};

export const getServiceList = (selectedServices) => {
  return selectedServices && Object.values(selectedServices).length > 0
  ? (Object.keys(selectedServices).map(function (k) { return selectedServices[k] })).flat(1) : [];
};

export const getTimeSlot = (article, dates) => {
  if (isArray(article) && article[0]?.availableDays && dates) {
    // There will be situations where the redux store has older data
    // so there will not always be a match, this can return undefined or null
    //Callers need to be able to handle that.
    const returnValue = article[0]?.availableDays[dates];
    return returnValue;
  } else if (article && article?.availableDays && dates) {
    const returnValue = article?.availableDays[dates];
    return returnValue;
  } else {
    return [];
  }
};

export const getAvailableDates = (stylist) => {
  if (isArray(stylist)) {
    if (
      isArray(stylist[0]) &&
      stylist[0][0]?.availableDays &&
      Object.keys(stylist[0][0]?.availableDays).length
    )
      return [...(Object.keys(stylist[0][0]?.availableDays) || [])];
    else if (
      isArray(stylist[0]?.availableDays) &&
      Object.keys(stylist[0]?.availableDays).length
    )
      return [...(Object.keys(stylist[0]?.availableDays) || [])];
    else return [];
  } else if (
    stylist &&
    stylist?.availableDays &&
    Object.keys(stylist?.availableDays).length
  ) {
    return [...(Object.keys(stylist.availableDays) || [])];
  } else return [];
};

const getImageURL = (stylist, serviceid) => {
  const getFlatArray = flatten(stylist);

  const imagePath = find(getFlatArray, { serviceId: serviceid });
  return imagePath ? imagePath?.imageUrl : null;
};

const getDuration = (stylist, serviceid) => {
  const getFlatArray = flatten(stylist);
  const serviceList = find(getFlatArray, { serviceId: serviceid });
  return serviceList ? serviceList?.duration : null;
};

const getCurrentTime = (selectedDate, eachval, timezone) => {
  const _currentTime = moment().tz(timezone); //moment.tz(`${"2022-10-26"} ${"16:00"}`, timezone); //
  const _startAt = moment.tz(`${selectedDate} ${eachval}`, timezone);
  const timeDiff = moment.duration(_startAt.diff(_currentTime)).asSeconds();
  return timeDiff > 0;
};

export const getSelectedStylistData = (stylist, date, selectedStylist) => {
  if (isArray(stylist)) {
    if (
      isArray(stylist[0]) &&
      stylist[0][0]?.availableDays &&
      Object.keys(stylist[0][0]?.availableDays).length
    ) {
      const _currentDate = moment()
        .tz(stylist[0][0]?.zone)
        .format("YYYY-MM-DD");
      return selectedStylist.map((each) => ({
        ...each,
        availableDays: {
          [date]:
            _currentDate === date
              ? stylist[0][0]?.availableDays[date].filter((eachval) => {
                  return getCurrentTime(date, eachval, stylist[0][0]?.zone);
                }) || []
              : [...(stylist[0][0]?.availableDays[date] || [])],
        },
        imageUrl: getImageURL(stylist, each?.serviceId),
        duration: getDuration(stylist, each?.serviceId)
      }));
    } else if (
      isArray(stylist[0]?.availableDays) &&
      Object.keys(stylist[0]?.availableDays).length
    )
      return selectedStylist.map((each) => ({
        ...each,
        availableDays: {
          [date]:
            moment().tz(stylist[0]?.zone).format("YYYY-MM-DD") === date
              ? stylist[0]?.availableDays[date].filter((eachval) => {
                  return getCurrentTime(date, eachval, stylist[0]?.zone);
                }) || []
              : [...(stylist[0]?.availableDays[date] || [])],
        },
        // availableDays: { [date]: [...(stylist[0]?.availableDays[date] || [])] },
        imageUrl: getImageURL(stylist, each?.serviceId),
        duration: getDuration(stylist, each?.serviceId)
      }));
    else
      return selectedStylist.map((each) => ({
        ...each,
        availableDays: {},
        imageUrl: getImageURL(stylist, each?.serviceId),
        duration: getDuration(stylist, each?.serviceId)
      }));
  } else if (
    stylist &&
    stylist?.availableDays &&
    Object.keys(stylist?.availableDays).length
  ) {
    return selectedStylist.map((each) => ({
      ...each,
      availableDays: {
        [date]:
          moment().tz(stylist?.zone).format("YYYY-MM-DD") === date
            ? stylist?.availableDays[date].filter((eachval) => {
                return getCurrentTime(date, eachval, stylist?.zone);
              }) || []
            : [...(stylist?.availableDays[date] || [])],
      },
      imageUrl: getImageURL(stylist, each?.serviceId),
      duration: getDuration(stylist, each?.serviceId)
    }));
  } else
    return selectedStylist.map((each) => ({
      ...each,
      availableDays: {},
      imageUrl: getImageURL(stylist, each?.serviceId),
      duration: getDuration(stylist, each?.serviceId)
    }));
};
export const getSelectedStoreData = (data) => ({
  locationID: data.locationId,
  name: data.locationName,
  OrganizationId: data.organizationId,
  geo: data?.geo,
  phoneNumber: data?.phoneNumber,
  storeNum: data?.storeNum,
  addressLine1: data?.addressLine1,
  addressCity: data?.addressCity,
  addressState: data?.addressState,
  addressZip: data?.addressZip,
  addressCountry: data?.addressCountry,
  timezone: data?.timeZone,
  addressLine2: data?.addressLine2,
  appointmentId: data?.appointmentId,
});

export const getSelectedSService = (value) => {
  const getdata = [];
  value.forEach((each, index) => {
    let price = 0;

    each?.bookedState?.services.forEach((prices) => {
      if (prices.serviceId === each.serviceId)
        price = prices?.finalPriceCents || 0;
    });

    getdata.push({
      ...each,
      Price: price,
      service: each?.serviceName,
      teammateId: each?.teamMateid,
      durationMin: each?.bookedState
        ? Math.round(each?.bookedState?.services[index].finalDurationSec / 60)
        : each.durationSec
        ? Math.round(each.durationSec / 60)
        : "",
    });
  });
  return getdata;
};

export const getStylistforReview = (selectedStylist, serviceID) => {
  if (
    find(selectedStylist, {
      serviceId: serviceID,
    })?.firstName
  ) {
    if (
      find(selectedStylist, {
        serviceId: serviceID,
      })?.lastName
    ) {
      return `
          ${
            find(selectedStylist, {
              serviceId: serviceID,
            })?.firstName
          }.
          ${
            find(selectedStylist, {
              serviceId: serviceID,
            })?.lastName &&
            find(selectedStylist, {
              serviceId: serviceID,
            })?.lastName.charAt(0)
          }
          
           `;
    } else {
      return `
        ${
          find(selectedStylist, {
            serviceId: serviceID,
          })?.firstName
        }
        
         `;
    }
  }
};

export const formatStylistName = (selectedStylist, teammateId) => {
  const stylist = selectedStylist.find((data) => ( data?.teammateId || data?.teamMateid || data?.TeammateId ) === teammateId) ?? null 
  if(stylist?.firstName){
    if(stylist?.lastName){
      return `${stylist?.firstName} ${stylist?.lastName.charAt(0)}.`;   
    }
    else{
      return `${stylist?.firstName}`; 
    }
  }
}

export const fetchTeammateId = (selectedStylist, serviceID) => {
  const stylist = selectedStylist.find((stylist) => stylist.serviceId === serviceID)
  return stylist?.teammateId ?? stylist?.teamMateid
}

export const fetchServiceDuration = (selectedStylist, eachSelectedService) => {
  const stylist = selectedStylist.find((data) => ( data?.serviceId || data?.serviceID ) === eachSelectedService.serviceID) ?? null;
  return stylist?.duration ? stylist?.duration : 0;
}

export const getStylistWithServicesForServiceFlow = (selectedStylist, selectedServices) => {
  let stylistWithServices = {};
  Object.keys(selectedServices).forEach( (each) => {
    const selectedCat = selectedServices[each];
    selectedCat.forEach( (service) => {
        const teammateId = fetchTeammateId(selectedStylist, service.serviceID ?? service.serviceId );
        stylistWithServices[teammateId] = stylistWithServices[teammateId] || [];
        stylistWithServices[teammateId].push(service);
    })
  });

  return stylistWithServices;
}

export const getStylistWithServicesForStylistFlow = (selectedStylist, selectedServices) => {
  let stylistWithServices = {};
  selectedStylist.forEach((stylist) => {
      let stylistServices = [];
      const teammateId = stylist.TeammateId;
      Object.keys(selectedServices).forEach((service) => {
          const curservice = selectedServices[service];
          curservice.map((each) => {
              if(each.TeammateId === teammateId){
                  stylistServices.push(each);
          } 
          })
      })
      if(stylistServices.length > 0) 
          stylistWithServices[teammateId] = stylistServices;
  })

  return stylistWithServices;
}

export const phoneFormat = (input) => {
  input = input.replace(/\D/g, "");
  input = input.substring(1);
  input =
    "(" +
    input.substring(0, 3) +
    ") " +
    input.substring(3, 6) +
    "-" +
    input.substring(6, 10);
  return input;
};

export const getSelectedPriceForService = (selectedServices, value) => {
  const clonneSelectedServices = clone(selectedServices);
  Object.keys(selectedServices).forEach((eachService) => {
    selectedServices[eachService].forEach((each, index) => {
      const getPrice = find(value?.getIndex, {
        serviceId: each?.serviceID || each?.serviceId,
      });
      let price = getPrice ? getPrice?.price : 0;
      if (isArray(getPrice && getPrice?.bookedState?.services)) {
        price = 0;
        const getPriceBooking = find(getPrice?.bookedState?.services, {
          serviceId: each?.serviceId || each?.serviceId,
        });

        price += getPriceBooking?.finalPriceCents || 0;
      }
      clonneSelectedServices[eachService][index] = {
        ...each,
        Price: price,
      };
    });
  });
  return clonneSelectedServices;
};

export const getSelectedStylistDataWithoutDate = (stylist, selectedStylist) => {
  if (isArray(stylist)) {
    if (
      isArray(stylist[0]) &&
      stylist[0][0]?.availableDays &&
      Object.keys(stylist[0][0]?.availableDays).length
    ) {
      return selectedStylist.map((each) => ({
        ...each,
        imageUrl: getImageURL(stylist, each?.serviceId),
      }));
    } else if (
      isArray(stylist[0]?.availableDays) &&
      Object.keys(stylist[0]?.availableDays).length
    )
      return selectedStylist.map((each) => ({
        ...each,

        imageUrl: getImageURL(stylist, each?.serviceId),
      }));
    else
      return selectedStylist.map((each) => ({
        ...each,
        availableDays: {},
      }));
  } else if (
    stylist &&
    stylist?.availableDays &&
    Object.keys(stylist?.availableDays).length
  ) {
    return selectedStylist.map((each) => ({
      ...each,
      imageUrl: stylist?.imageUrl,
    }));
  } else
    return selectedStylist.map((each) => ({
      ...each,
      availableDays: {},
    }));
};

const getTimePills = (data, timezone, selectedDate) => {
  const cloneData = clone(data);
  Object.keys(data).forEach((each) => {
    cloneData[each] = [
      ...(data[each].filter((eachval) => {
        return getCurrentTime(selectedDate, eachval, timezone);
      }) || []),
    ];
  });
  return cloneData;
};

export const getDiasbleTime = (data, timezone, selectedDate) => {
  const cloneData = clone(data);

  data.forEach((each, stylistIndex) =>
    isArray(each)
      ? each.filter((element, timeIndex) => {
          return (cloneData[stylistIndex][timeIndex] = {
            ...cloneData[stylistIndex][timeIndex],
            availableDays: getTimePills(
              element?.availableDays,
              timezone,
              selectedDate
            ),
          });
        })
      : (cloneData[stylistIndex] = {
          ...cloneData[stylistIndex],
          availableDays: getTimePills(
            each.availableDays,
            timezone,
            selectedDate
          ),
        })
  );
  return cloneData;
};

export const sortByFName = (s1, s2) => {
  let index = 0;
  while(index < s1.length && index < s2.length) {
    const firstNameA = s1[index]?.firstName;
    const firstNameB = s2[index]?.firstName;
    const comparision = firstNameA.localeCompare(firstNameB);
    if(comparision !== 0){
      return comparision;
    }
    index++;
  }

  return s1.length - s2.length;
}

export const isAllTeammatesIdSame = (teamMatesArr) => {
  const firstTeammateId = teamMatesArr[0].teammateId;
  return teamMatesArr.every(tm => tm.teammateId === firstTeammateId);
}

export const hasAvailableDaysKey = (stylistArr) => {
  return stylistArr.every(st => st.hasOwnProperty("availableDays") === true);
}

export const reOrderStylistData = (stylistdata) => {
  const sameTeamMatesArr = [];
  const restTeamMatesArr = [];
  const withAvailableDaysArr = [];
  const withoutAvailableDaysArr = [];
  const sameTeamMateswithoutAvailableDaysArr = [];
  const restTeamMateswithoutAvailableDaysArr= [];

  if(stylistdata && stylistdata.length > 0) {

    stylistdata.forEach(st => {
      if(hasAvailableDaysKey(st)){
        withAvailableDaysArr.push(st);
      } else {
        withoutAvailableDaysArr.push(st);
      }
    });
    const tempArr = [...withAvailableDaysArr];

    tempArr.forEach(s => {
      if(isAllTeammatesIdSame(s)){
        sameTeamMatesArr.push(s);
      } else {
        restTeamMatesArr.push(s);
      }
    });
    withoutAvailableDaysArr.forEach(s => {
      if(isAllTeammatesIdSame(s)){
        sameTeamMateswithoutAvailableDaysArr.push(s);
      } else {
        restTeamMateswithoutAvailableDaysArr.push(s);
      }
    });
  }
  return [...sameTeamMatesArr.sort(sortByFName), ...restTeamMatesArr.sort(sortByFName),...sameTeamMateswithoutAvailableDaysArr.sort(sortByFName),...restTeamMateswithoutAvailableDaysArr.sort(sortByFName)];
} 

export const getStylistInfoById = (editTeammateId, stylistFlowData) => {
  const stylistInfo = stylistFlowData && stylistFlowData.find( (stylistData) => stylistData.TeammateId === editTeammateId );
  return stylistInfo;
}

export const formatSelectedStylistForServiceFlow = (selectedstylist, stylistFlowData) => {
  const uniqueTeammateIds = [ ...new Set(selectedstylist.map(stylist => stylist.teammateId ?? stylist.teamMateid))];
  const formatSelectedStylistForStylistFlow = [];

  uniqueTeammateIds.forEach( (selectedstylist) => {
    const stylistInfo = stylistFlowData && stylistFlowData.find(stylist => stylist.TeammateId === selectedstylist );
    if(stylistInfo) {
      const { OrganizationId, jobId, profileimageuuid, ...reststylistInfo } = stylistInfo;
      formatSelectedStylistForStylistFlow.push(reststylistInfo);
    }
  })

  return formatSelectedStylistForStylistFlow;
}

export const formatSelectedServiceForServiceFlow = (selectedstylist, selectedServices) => {
  Object.keys(selectedServices).forEach( (service) => {
    selectedServices[service].forEach((serviceObj) => {
        const serviceID = serviceObj.serviceID || serviceObj.serviceId
        const stylist = selectedstylist.find(s => s.serviceId === serviceID);
        const teammateId = stylist?.teammateId ?? stylist?.teamMateid;
        serviceObj.TeammateId = teammateId
    })
  })

  return selectedServices;
}

export const removeStylist = (selectedStylist, teammateId) => {
  const restSelectedStylist = selectedStylist.filter( (stylist) => stylist.TeammateId !== teammateId );
  return restSelectedStylist.length > 0 ? restSelectedStylist : null;
}

export const removedStylistServices = (selectedServices, teammateId) => {
  let updatedServices = null;
  Object.keys(selectedServices).forEach( (eachCategory) => {
      const category = selectedServices[eachCategory];
      const remainingServices = category.filter( (eachServices) => eachServices.TeammateId !== teammateId );
      if(remainingServices.length > 0) {
           updatedServices = {
              ...updatedServices,
              [eachCategory]: remainingServices
      }   
      }
  })

  return updatedServices;
}
export const resetAppointmentBooking = (config) => {
  const  { props, resetStylist } = config;

  if(props?.reserveAppt?.id) {
    props?.reserveAppt &&
      props?.deleteAppointmentSoftBooking(props?.reserveAppt?.id);
    props?.removeReserveData(null);
    
    if(resetStylist) {
      props.removeStylistcard(null);
    }
    props?.failedConfirmationDate?.dates &&
      !props?.selectedDate?.dates &&
      props.getSelectedDate({
        ...props?.failedConfirmationDate,
      });
  }
}

export const getStylistWithServices = (serviceList) => {
  let stylistWithServices = {};
  serviceList.forEach((service) => {
    const teammateId = service.teamMateid;
    stylistWithServices[teammateId] = stylistWithServices[teammateId] || [];
    stylistWithServices[teammateId].push(service);
  })

  return stylistWithServices;
}

export const handleDownloadCalendar = (appointmentId) => {
  return function () {
    let alink = document.createElement("a");
    alink.href = "/api/appointments/v1/calendar/download?id=" + appointmentId;
    alink.download = "Ulta.ics";
    alink.click();
  };
};