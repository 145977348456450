export const SET_BOOKAGAIN_DATA = "SET_BOOKAGAIN_DATA";

export const FETCH_DASHBOARDBANNER_DATA_SUCCESS = 
  "FETCH_DASHBOARDBANNER_DATA_SUCCESS";

export const FETCH_DASHBOARDBANNER_DATA_FAILURE = 
  "FETCH_DASHBOARDBANNER_DATA_FAILURE";

export const FETCH_PREVIOUSSALONVISITED_DATA =
  "FETCH_PREVIOUSSALONVISITED_DATA";
export const FETCH_PREVIOUSSALONVISITED_DATA_SUCCESS =
  "FETCH_PREVIOUSSALONVISITED_DATA_SUCCESS";
export const FETCH_PREVIOUSSALONVISITED_DATA_FAILURE =
  "FETCH_PREVIOUSSALONVISITED_DATA_FAILURE";

export const FETCH_UPCOMING_APPTS_DATA = "FETCH_UPCOMING_APPTS_DATA";
export const FETCH_UPCOMING_APPTS_DATA_SUCCESS =
  "FETCH_UPCOMING_APPTS_DATA_SUCCESS";
export const FETCH_UPCOMING_APPTS_DATA_FAILURE =
  "FETCH_UPCOMING_APPTS_DATA_FAILURE";

export const DELETE_APPT_DATA = "DELETE_APPT_DATA";
export const DELETE_APPT_DATA_SUCCESS = "DELETE_APPT_DATA_SUCCESS";
export const DELETE_APPT_DATA_FAILURE = "DELETE_APPT_DATA_FAILURE";

export const FETCH_DASHBOARDUSERINFO_DATA = "FETCH_DASHBOARDFLYOUT_DATA";
export const FETCH_DASHBOARDUSERINFO_DATA_SUCCESS =
  "FETCH_DASHBOARDFLYOUT_DATA_SUCCESS";
export const FETCH_DASHBOARDUSERINFO_DATA_FAILURE =
  "FETCH_DASHBOARDFLYOUT_DATA_FAILURE";

export const LOGOUT_USERINFO_DATA = "LOGOUT_USERINFO_DATA";
export const LOGOUT_USERINFO_DATA_SUCCESS = "LOGOUT_USERINFO_DATA_SUCCESS";
export const LOGOUT_USERINFO_DATA_FAILURE = "LOGOUT_USERINFO_DATA_FAILURE";
export const RESET_USERINFO_DATA = "RESET_USERINFO_DATA";

export const DELETE_APPT_SERVICE_DATA = "DELETE_APPT_SERVICE_DATA";
export const DELETE_APPT_SERVICE_DATA_SUCCESS =
  "DELETE_APPT_SERVICE_DATA_SUCCESS";
export const DELETE_APPT_SERVICE_DATA_FAILURE =
  "DELETE_APPT_SERVICE_DATA_FAILURE";

export const FETCH_PASTAPPOINTMENTS_DATA = "FETCH_PASTAPPOINTMENTS_DATA";
export const FETCH_PASTAPPOINTMENTS_DATA_SUCCESS =
  "FETCH_PASTAPPOINTMENTS_DATA_SUCCESS";
export const FETCH_PASTAPPOINTMENTS_DATA_FAILURE =
  "FETCH_PASTAPPOINTMENTS_DATA_FAILURE";
export const SET_CART_BANNER_FLAG_DATA = "SET_CART_BANNER_FLAG_DATA";